<interacta-dialog
    [closeOnBackdropClick]="false"
    [contentOverflowAuto]="false"
    [isOpen]="isOpen"
    [size]="'small'"
    (closing)="closing.emit()"
>
    <div class="typo-t6 flex" title>
        {{
            (content === 'community-selection'
                ? 'DASHBOARD.ATTACHMENTS.SHARE_DIALOG.COMMUNITY.TITLE'
                : 'DASHBOARD.ATTACHMENTS.SHARE_DIALOG.HASHTAG.TITLE'
            ) | translate
        }}
    </div>

    <div class="flex min-h-0 flex-col gap-16 overflow-hidden" content>
        <interacta-dialog-description>
            <span
                [ngClass]="{
                    'text-text': content === 'hashtag-reclassification'
                }"
                >{{
                    (content === 'community-selection'
                        ? 'DASHBOARD.ATTACHMENTS.SHARE_DIALOG.COMMUNITY.SUB_TITLE'
                        : 'DASHBOARD.ATTACHMENTS.SHARE_DIALOG.HASHTAG.SUB_TITLE'
                    ) | translate
                }}</span
            >
            <span
                *ngIf="
                    content === 'hashtag-reclassification' && selectedCommunity
                "
                class="typo-sm-bold text-text-primary"
                >{{ selectedCommunity.name }}</span
            >
        </interacta-dialog-description>

        <interacta-input-search
            *ngIf="content === 'community-selection' && communities.length > 20"
            iconPosition="right"
            [control]="searchControl"
        ></interacta-input-search>
        <interacta-community-selection
            *ngIf="content === 'community-selection'"
            class="overflow-y-auto"
            [communities]="communities"
            [searchControl]="searchControl"
            (selectCommunity)="selectedCommunity = $event"
        ></interacta-community-selection>

        <attachments-hashtag-reclassification
            *ngIf="content === 'hashtag-reclassification'"
            class="flex min-h-0 grow"
            [community]="selectedCommunity"
            [hashtags]="hashtags"
            (applyChanges)="createPostWithUpdatedHashtag($event)"
            (back)="content = 'community-selection'"
        ></attachments-hashtag-reclassification>
    </div>
    <interacta-dialog-buttons *ngIf="content === 'community-selection'">
        <button
            interacta-dialog-button
            type="button"
            [appearance]="'ghost'"
            [bgColor]="'surface-A'"
            [label]="'BUTTON.LABEL_BUTTON_CANCEL' | translate"
            (click)="closing.emit()"
        ></button>
        <button
            interacta-dialog-button
            type="button"
            [appearance]="'fill'"
            [bgColor]="'primary'"
            [disabled]="!selectedCommunity"
            [label]="'RELATIONS_DIALOG.LINK.CREATE_AND_LINK' | translate"
            (click)="selectedCommunity && createPost()"
        ></button>
    </interacta-dialog-buttons>
</interacta-dialog>
