<div class="flex w-full flex-col gap-16">
    <div
        #container
        class="flex flex-col gap-16 overflow-y-auto overflow-x-hidden pr-8"
    >
        @for (
            hashtagRadio of hashtagsRadioList;
            track hashtagRadio.hashtag.id
        ) {
            <interacta-radio-group
                [(ngModel)]="hashtagRadio.radioOption"
                (changeSelection)="
                    $event === 'ignore' && (hashtagRadio.replacement = null)
                "
            >
                <div class="flex flex-col gap-16">
                    <interacta-separator />
                    <interacta-hashtag-chip
                        class="self-start"
                        [label]="'#' + hashtagRadio.hashtag.name"
                    />
                    <p class="typo-xs">
                        {{
                            hashtagRadio.hashtag.occurrences
                                | i18nPlural
                                    : {
                                          '=1': 'DASHBOARD.ATTACHMENTS.SHARE_DIALOG.HASHTAG.OCCURRENCES_SING',
                                          other: 'DASHBOARD.ATTACHMENTS.SHARE_DIALOG.HASHTAG.OCCURRENCES_PLUR'
                                      }
                                | translate
                                    : {
                                          occurrences:
                                              hashtagRadio.hashtag.occurrences
                                      }
                        }}
                    </p>

                    @for (option of radioOptions; track option.value) {
                        <interacta-radio-button [value]="option.value">
                            <p class="typo-sm">
                                {{ option.label | translate }}
                            </p>
                        </interacta-radio-button>
                    }

                    <div class="flex flex-col gap-8">
                        <interacta-hashtag-filter
                            [availableHashtags]="community.metadata.hashtags"
                            [hashtagFilter]="hashtagRadio.replacement"
                            [isReadonly]="hashtagRadio.radioOption === 'ignore'"
                            (selectedHashtag)="
                                hashtagRadio.replacement = $event
                            "
                        />
                        @if (
                            hashtagRadio.replacementHint &&
                            !hashtagRadio.replacement
                        ) {
                            <button
                                class="rounded-4 bg-surface-primary-low-contrast typo-xs flex self-start px-8 py-4"
                                type="button"
                                (click)="selectHint(hashtagRadio)"
                            >
                                {{
                                    'DASHBOARD.ATTACHMENTS.SHARE_DIALOG.HASHTAG.HINT'
                                        | translate
                                            : {
                                                  replacementHintName:
                                                      hashtagRadio
                                                          .replacementHint.name
                                              }
                                }}
                            </button>
                        }
                    </div>
                </div>
            </interacta-radio-group>
        }
    </div>
    <interacta-dialog-buttons>
        <button
            interacta-dialog-button
            type="button"
            [appearance]="'ghost'"
            [bgColor]="'surface-A'"
            [label]="'BUTTON.LABEL_BUTTON_BACK' | translate"
            (click)="back.emit()"
        ></button>
        <button
            interacta-dialog-button
            type="button"
            [appearance]="'fill'"
            [bgColor]="'primary'"
            [label]="'BUTTON.LABEL_BUTTON_APPLY' | translate"
            (click)="applyHashtagsChanges()"
        ></button>
    </interacta-dialog-buttons>
</div>
