import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { InputSelectSearchEvent } from '@interacta-shared/ui';
import { IHashTag } from '@modules/communities/models/hashtag/hashtag.model';
import { BehaviorSubject, Subject, map, startWith, takeUntil } from 'rxjs';

@Component({
    selector: 'interacta-hashtag-filter',
    templateUrl: './hashtag-filter.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HashtagFilterComponent implements OnChanges, OnDestroy {
    @Input({ required: true }) availableHashtags: IHashTag[] = [];
    @Input() hashtagFilter: IHashTag | null = null;
    @Input() isReadonly = false;

    @Output()
    selectedHashtag = new EventEmitter<IHashTag | null>();

    hashtags$ = new BehaviorSubject<IHashTag[]>([]);
    hashtagControl = new FormControl<IHashTag | null>(null);

    private destroy$ = new Subject<void>();
    private init$ = new Subject<void>();

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.availableHashtags) {
            this.hashtags$.next(this.availableHashtags);
        }

        if (changes.hashtagFilter) {
            this.init$.next();
            this.hashtagControl.setValue(this.hashtagFilter);

            const hashtagChanges$ = this.hashtagControl.valueChanges.pipe(
                startWith(this.hashtagFilter),
                map(
                    (val: any): IHashTag =>
                        Array.isArray(val) ? val[0] ?? null : val,
                ),
                takeUntil(this.destroy$),
                takeUntil(this.init$),
            );

            hashtagChanges$.subscribe((hashtag: IHashTag | null) =>
                this.selectedHashtag.emit(hashtag),
            );
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
    }

    search(search: InputSelectSearchEvent): void {
        this.hashtags$.next(
            this.availableHashtags.filter(
                (h) =>
                    !search.text ||
                    h.name
                        .toLowerCase()
                        .indexOf(search.text.toLocaleLowerCase()) > -1,
            ),
        );
    }
}
